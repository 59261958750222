import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Breadcrumbs from '../components/Breadcrumbs';
import ModularBlocks from '../components/ModularBlocks';
import TestimonialBlock from '../components/TestimonialBlock';
import ProjectCardListing from '../components/ProjectCardListing';

const ProjectSingleTemplate = ({
  data: {
    datoCmsProject: {
      seoMetaTags,
      title,
      bannerText,
      bannerImage,
      modularBlocks,
      testimonialQuote,
      testimonialAuthor,
      relatedProjects,
    },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner heading={title} text={bannerText} image={bannerImage} />
      <Breadcrumbs
        breadcrumb={{ slug: 'projects', text: 'Projects' }}
        currentPageTitle={title}
      />
      <ModularBlocks items={modularBlocks} />
      {testimonialQuote && (
        <TestimonialBlock
          heading="Client Feedback"
          quote={testimonialQuote}
          author={testimonialAuthor}
        />
      )}
      <ProjectCardListing
        heading="Other Projects"
        link="projects"
        items={relatedProjects}
        isProjectSingle={true}
      />
    </main>
  </Layout>
);

export const ProjectSingleTemplateQuery = graphql`
  query ProjectSingleTemplateQuery($id: String!) {
    datoCmsProject(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerText
      bannerImage {
        ...SubpageBannerImageFragment
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedEquipmentModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageSliderModularBlockFragment
        ...VideoContentModularBlockFragment
        ...VideoModularBlockFragment
      }
      testimonialQuote
      testimonialAuthor
      relatedProjects {
        ...ProjectCardFragment
      }
    }
  }
`;

export default ProjectSingleTemplate;
